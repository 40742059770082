import React from "react";
import { useRoutes } from "react-router-dom";
import Markt from "pages/Markt"; // Atualizado de "Home" para "Markt"
import Login from "pages/Login";
import Register from "pages/Register1";
import CadastroLinks from "pages/CadastroLinks";
import CadastroImagens from "pages/CadastroImagens";
import CadastroDados from "pages/CadastroDados";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Markt /> }, // Atualizado de "Home" para "Markt"
    { path: "/home", element: <Markt /> }, // Atualizado de "Home" para "Markt"
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/cadastro-links", element: <CadastroLinks /> },
    { path: "/cadastro-imagens", element: <CadastroImagens /> },
    { path: "/cadastro-dados", element: <CadastroDados /> },
  ]);

  return element;
};

export default ProjectRoutes;
