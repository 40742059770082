import { Button, Input, Text, Heading, Img } from "../../components";
import React from "react";
import "./FrametwoColumnThree.css"; // Importa o arquivo CSS personalizado

export default function FrametwoColumnThree() {
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center px-10 md:px-4 sm:px-3"
      style={{
        transform: "scale(0.8)", // Reduz o tamanho para 80% do original
        transformOrigin: "top center", // Define o ponto de origem para a escala
      }}
    >
      <div className="flex w-full flex-col items-center gap-[50px] lg:w-full md:w-full sm:gap-[25px]">
        <div className="flex items-center justify-center self-stretch md:flex-col">
          <div className="flex-1 self-center md:self-stretch">
            <div className="flex flex-col items-center gap-[50px] sm:gap-[25px]">
              <div className="flex items-center justify-center self-stretch sm:flex-col">
                {/* Logo */}
                <div className="flex h-auto w-[20%] items-center justify-center py-1 lg:h-auto md:h-auto sm:w-[30%] sm:order-1">
                  <Img
                    src="images/logo.png"
                    alt="Logo"
                    className="h-[64px] w-[192px] object-contain"
                    loading="lazy"
                  />
                </div>

                {/* Imagem "123" com maior ajuste para a esquerda */}
                <div className="flex flex-1 justify-center px-0 md:px-4 sm:px-3 sm:order-2">
                  <Img
                    src="images/123-1.png"
                    alt="Step image 123"
                    className="h-[64px] w-[192px] object-contain translate-x-[-110px]" // Deslocamento maior para a esquerda
                    loading="lazy"
                  />
                </div>

                {/* Botão "Seja Premium" */}
                <button
                  className="animate-gradient h-[64px] w-[192px] rounded-[10px] px-[30px] py-[10px] font-bold tracking-[0.16px] sm:px-4 sm:order-3"
                >
                  Seja Premium
                </button>
              </div>

              {/* Centralized text */}
              <div className="flex flex-col items-center justify-center w-full gap-4">
                <Heading
                  size="text5xl"
                  as="h4"
                  className="text-[22.68px] font-medium leading-[27px] tracking-[0.23px] lg:text-[18px] text-center"
                  style={{
                    color: "#000000",
                  }}
                >
                  <span>Após a conclusão,&nbsp;</span>
                  <span className="font-light">
                    <>
                      nosso suporte analisará e entrará em
                      <br />
                      contato sobre a aprovação, ativando seu perfil no catálogo.
                    </>
                  </span>
                </Heading>
              </div>

              <Img
                src="images/img_design_sem_nome.png"
                alt="Designsemnome"
                className="mx-auto h-auto w-[40%] lg:w-[50%] md:w-[60%] sm:w-[80%] object-contain"
              />
            </div>
          </div>
        </div>
        <div className="container-xs lg:px-5 md:px-5">
          <div className="flex flex-col items-center gap-[100px] lg:gap-[100px] md:gap-[75px] sm:gap-[50px]">
            <div className="flex flex-col items-center gap-14 self-stretch rounded-[30px] bg-gray-50 p-[52px] shadow-xs md:p-5 sm:gap-7 sm:p-4">
              <div className="mx-1.5 flex items-center gap-[15px] md:mx-0 sm:flex-col">
                <Img
                  src="images/img_vector_1_32x26.png"
                  alt="Vectorone"
                  className="h-[32px] rounded-lg object-cover sm:w-full hide-mobile"
                  loading="lazy"
                />
                <Heading
                  size="heading4xl"
                  as="h5"
                  className="text-[32px] font-bold tracking-[0.32px] lg:text-[27px] md:text-[26px] sm:text-[24px]"
                  style={{ color: "#000000" }}
                >
                  Links para divulgar seu perfil
                </Heading>
              </div>
              <div className="flex w-full flex-col gap-[60px] lg:w-full md:w-full sm:gap-[30px]">
                {[
                  { label: "Link Bot (Telegram Vip)", placeholder: "Link do Bot (Telegram Vip)" },
                  { label: "Link do canal grátis (Telegram Free)", placeholder: "Link do canal grátis" },
                  {
                    label: "Link (OnlyFans)",
                    placeholder: "Torne-se Premium",
                    disabled: true,
                    locked: true,
                  },
                  { label: "Link (Privacy)", placeholder: "Torne-se Premium", disabled: true, locked: true },
                  { label: "Link (FanFever)", placeholder: "Torne-se Premium", disabled: true, locked: true },
                  { label: "Link (Peeper)", placeholder: "Torne-se Premium", disabled: true, locked: true },
                  { label: "Link (Instagram)", placeholder: "Torne-se Premium", disabled: true, locked: true },
                  { label: "Link (Tiktok)", placeholder: "Torne-se Premium", disabled: true, locked: true },
                ].map((item, index) => (
                  <div className="flex flex-col items-start gap-5" key={index}>
                    <Text
                      as="p"
                      className="text-[18px] font-normal tracking-[0.18px] lg:text-[15px]"
                      style={{ color: "#000000" }}
                    >
                      {item.label}
                    </Text>
                    <div className="relative w-full">
                      <Input
                        shape="round"
                        name={`link_${index}`}
                        placeholder={item.placeholder}
                        className="self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px]"
                        disabled={item.disabled}
                      />
                      {item.locked && (
                        <span className="absolute inset-y-0 right-3 flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="h-6 w-6 text-gray-500"
                          >
                            <path d="M12 1a5 5 0 00-5 5v4H5a3 3 0 00-3 3v7a3 3 0 003 3h14a3 3 0 003-3v-7a3 3 0 00-3-3h-2V6a5 5 0 00-5-5zm-3 5a3 3 0 116 0v4H9V6zm10 7v7a1 1 0 01-1 1H5a1 1 0 01-1-1v-7a1 1 0 011-1h14a1 1 0 011 1z" />
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex gap-[30px]">
              <Button
                variant="fill"
                shape="round"
                className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4"
              >
                Anterior
              </Button>
              <button className="animate-gradient min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4">
                Próximo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
