import { Img, Heading, Slider } from "../../components";
import React from "react";

export default function HighlightsSection() {
  const [sliderState, setSliderState] = React.useState(0);
  const sliderRef = React.useRef(null);

  return (
    <>
      {/* highlights section */}
      <div className="flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <div className="mx-auto flex w-full">
            <Slider
              autoPlay
              autoPlayInterval={2000}
              responsive={{ 0: { items: 1 }, 551: { items: 1 }, 1051: { items: 1 }, 1441: { items: 1 } }}
              disableDotsControls
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={[...Array(3)].map(() => (
                <React.Fragment key={Math.random()}>
                  <div className="flex items-end rounded-[20px] bg-blue_gray-100 p-[52px] md:flex-col md:p-5 sm:p-4">
                    <div className="mt-[470px] flex w-[46%] flex-col items-start gap-2 md:w-full">
                      <Heading
                        size="headingxs"
                        as="h1"
                        className="flex items-center justify-center rounded-[5px] bg-blue_gray-900 px-2.5 text-[12px] font-bold tracking-[0.12px] text-white-a700"
                      >
                        DESTAQUES DA SEMANA
                      </Heading>
                      <Heading
                        size="headingxl"
                        as="h2"
                        className="text-[30px] font-bold tracking-[0.30px] text-white-a700 lg:text-[25px] md:text-[24px] sm:text-[22px]"
                      >
                        Fulana de Tal
                      </Heading>
                      <div className="flex items-center gap-2.5 self-stretch">
                        <Img src="images/img_favorite.svg" alt="Favorite Icon" className="h-[18px]" />
                        <Heading
                          size="headingmd"
                          as="h3"
                          className="text-[20.92px] font-bold tracking-[0.21px] text-white-a700 lg:text-[17px]"
                        >
                          45.115
                        </Heading>
                      </div>
                    </div>
                    <div className="mb-[54px] flex flex-1 justify-end gap-4 md:self-stretch">
                      <Img src="images/img_line_1.svg" alt="Line One" className="h-[4px] self-end" />
                      <Img src="images/img_line_2_gray_500.svg" alt="Line Two" className="h-[4px] self-end" />
                      <Img src="images/img_line_2_gray_500.svg" alt="Line Three" className="h-[4px] self-end" />
                      <Img src="images/img_line_2_gray_500.svg" alt="Line Four" className="h-[4px] self-end" />
                      <Img src="images/img_line_2_gray_500.svg" alt="Line Five" className="h-[4px] self-end" />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            />
          </div>
        </div>
      </div>
    </>
  );
}
