import { Heading } from "../../components";
import UserProfile1 from "../../components/UserProfile1";
import React, { Suspense } from "react";

const userProfileGrid = [
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
];

export default function FulanaProfileSection() {
  return (
    <>
      {/* fulana profile section */}
      <div className="mt-[50px] flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-[150px] lg:gap-28 lg:px-5 md:gap-28 md:px-5 sm:gap-[75px]">
          <div className="grid grid-cols-4 justify-center gap-3.5 self-stretch lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            <Suspense fallback={<div>Loading feed...</div>}>
              {userProfileGrid.map((d, index) => (
                <UserProfile1 {...d} key={"gridLayout" + index} />
              ))}
            </Suspense>
          </div>
          <div className="flex w-[20%] flex-wrap items-center justify-center gap-3.5 lg:w-full md:w-full">
            <Heading
              as="h2"
              className="text-shadow-ts flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-gradient text-center text-[22.68px] font-medium tracking-[0.23px] text-blue_gray-900 lg:text-[18px]"
            >
              1
            </Heading>
            <Heading
              as="h3"
              className="text-shadow-ts flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-blue_gray-100 text-center text-[22.68px] font-medium tracking-[0.23px] text-blue_gray-900 lg:text-[18px]"
            >
              2
            </Heading>
            <Heading
              as="h4"
              className="text-shadow-ts flex h-[42px] w-[42px] items-center justify-center rounded-[20px] bg-blue_gray-100 text-center text-[22.68px] font-medium tracking-[0.23px] text-blue_gray-900 lg:text-[18px]"
            >
              3
            </Heading>
            <Heading
              as="h5"
              className="self-end text-[22.68px] font-medium tracking-[0.23px] text-blue_gray-900 lg:text-[18px]"
            >
              ... 10 &gt;
            </Heading>
          </div>
        </div>
      </div>
    </>
  );
}
