import { useState } from "react";
import { Text, Button, CheckBox, Input, Heading, Img } from "../../components";
import React from "react";
import "./FramefiveRowOne.css";

export default function FramefiveRowOne() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    setIsLoading(true); // Ativa o loading
    setErrorMessage(""); // Limpa mensagens de erro anteriores
    try {
      const response = await fetch("http://52.87.32.57/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Erro ao realizar login. Verifique suas credenciais.");
      }

      const data = await response.json();

      localStorage.setItem("token", data.token);
      window.location.href = "/dashboard";
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false); // Desativa o loading
    }
  };

  return (
    <div
      className="page-container flex justify-center px-14 md:px-5 sm:px-4"
      style={{
        transform: "scale(0.8)", // Escala para 80% do tamanho original
        transformOrigin: "top center", // Ponto de origem para a transformação
      }}
    >
      <div className="mx-auto flex w-full max-w-[1298px] items-center justify-center md:flex-col">
        <div className="relative mb-5 h-[698px] flex-1 self-end md:w-full md:flex-none md:self-stretch sm:hidden">
          <Img
            src="images/img_0_1_2_690x386.png"
            alt="Image"
            className="absolute bottom-0 left-[10.26px] top-0 my-auto h-[690px] w-[52%] object-contain"
          />
          <div className="absolute bottom-0 left-0 right-0 top-0 m-auto h-[690px] flex-1 content-center lg:h-auto md:h-auto">
            <Img
              src="images/img_0_1_1_690x386.png"
              alt="Image"
              className="h-[690px] w-[52%] object-contain"
            />
            <div className="absolute left-[32%] top-[25px] m-auto flex h-[40px] w-[26%] items-center justify-center">
              <Img
                src="images/logo.png"
                alt="Logo"
                className="h-auto w-full object-contain"
              />
            </div>
          </div>
        </div>

        <div className="flex w-[42%] flex-col items-center justify-center gap-[54px] rounded-[30px] bg-gray-900_02 px-[42px] py-[60px] shadow-xl md:w-full md:p-5 sm:gap-[27px] sm:p-4">
          <div className="hidden sm:flex mb-5">
            <Img
              src="images/logo.png"
              alt="Logo"
              className="h-12 w-auto object-contain"
            />
          </div>

          <Heading
            size="heading4xl"
            as="h1"
            className="text-[32px] font-bold tracking-[0.32px] text-white-a700 lg:text-[27px] md:text-[26px] sm:text-[24px] text-center"
          >
            <span className="text-white-a700">
              <>
                Aumente sua audiência
                <br />
                e&nbsp;
              </>
            </span>
            <span className="bg-gradient bg-clip-text text-transparent">
              fature mais!
            </span>
          </Heading>

          <div className="mb-1.5 self-stretch">
            <div className="flex flex-col items-center">
              <Heading
                size="headingmd"
                as="h2"
                className="ml-1 self-start text-[16px] font-normal tracking-[0.16px] text-white-a700 lg:text-[13px] md:ml-0 text-center"
              >
                <span>Acesse sua&nbsp;</span>
                <span>conta</span>
                <span>:</span>
              </Heading>

              <div className="mt-8 flex flex-col items-start gap-4 self-stretch">
                <Text
                  as="p"
                  className="ml-1 text-[18px] font-normal tracking-[0.18px] text-white-a700 lg:text-[15px] md:ml-0"
                >
                  E-mail
                </Text>
                <Input
                  color="gray_400_01"
                  size="sm"
                  shape="round"
                  name="email"
                  className="self-stretch rounded-lg px-[18px] bg-custom-dark text-white"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  }}
                />
              </div>

              <div className="mt-4 flex flex-col items-start gap-4 self-stretch">
                <Text
                  as="p"
                  className="ml-1 text-[18px] font-normal tracking-[0.18px] text-white-a700 lg:text-[15px] md:ml-0"
                >
                  Senha
                </Text>
                <Input
                  color="gray_400_01"
                  shape="round"
                  type="password"
                  name="password"
                  className="self-stretch rounded-lg px-[18px] bg-custom-dark text-white"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    backgroundColor: "#1e1e1e",
                    color: "white",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  }}
                />
              </div>

              {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}

              <div className="mx-1.5 mt-4 flex justify-between gap-5 self-stretch md:mx-0 sm:flex-col">
                <CheckBox
                  name="mantenhameconec"
                  label="Mantenha-me conectado"
                  id="mantenhameconec"
                  className="gap-2 text-[16px] font-semibold tracking-[0.16px] text-white-a700 single-line"
                />
                <Button
                  size="sm"
                  className="bg-transparent text-[16px] font-normal tracking-[0.16px] text-transparent bg-clip-text bg-gradient lg:text-[13px] underline"
                >
                  Esqueci minha senha
                </Button>
              </div>

              <Button
                size="2xl"
                shape="round"
                color="purple_A200_cyan_A100"
                className="ml-1.5 mr-1 mt-8 self-stretch rounded-lg px-[34px] font-bold tracking-[0.19px] md:mx-0 sm:px-4 animate-gradient"
                onClick={handleLogin}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="spinner" />
                ) : (
                  "Entrar"
                )}
              </Button>

              <div className="flex flex-col items-center mt-9">
                <Text
                  as="p"
                  className="text-[18px] font-normal tracking-[0.18px] text-white-a700 lg:text-[15px] md:text-[16px]"
                >
                  Não tem uma conta?
                </Text>

                <a href="http://ec2-98-83-115-16.compute-1.amazonaws.com:3000/framefour">
                  <Button className="bg-transparent text-center text-[18px] font-normal tracking-[0.18px] text-transparent bg-clip-text bg-gradient lg:text-[15px] underline">
                    Cadastre-se
                  </Button>
                </a>

                <Button className="bg-transparent text-center text-[20px] font-black text-white-a700 underline mt-2">
                  Voltar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


