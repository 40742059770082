import { Heading, Img, Text } from "../../components";
import UserProfile from "../../components/UserProfile";
import React, { Suspense } from "react";

const productDetailsGrid = [
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
  { userName: "Fulana de tal", userScore: "76.411" },
];

export default function MainContentSection() {
  return (
    <>
      {/* main content section */}
      <div className="mt-[30px] flex flex-col items-center">
        <div className="container-xs flex flex-col gap-[30px] lg:px-5 md:px-5">
          <div>
            <div className="flex gap-[30px] md:flex-col">
              <div className="w-full rounded-[18px] bg-blue_gray-100 shadow-lg">
                <div className="mt-[428px] flex flex-col items-start">
                  <div className="relative z-[1] ml-9 h-[114px] w-[114px] rounded-[56px] border-[3.1px] border-solid border-blue_gray-900 bg-blue_gray-100 md:ml-0" />
                  <div className="relative mt-[-86px] flex items-center justify-end self-stretch rounded-bl-[18px] rounded-br-[18px] bg-gray-50 px-9 py-3 sm:px-4">
                    <div className="flex flex-col items-start gap-0.5">
                      <Heading
                        size="headinglg"
                        as="h2"
                        className="text-[24px] font-bold tracking-[0.24px] text-blue_gray-900 lg:text-[20px]"
                      >
                        Fulana de tal
                      </Heading>
                      <Text
                        size="textxl"
                        as="p"
                        className="text-[24px] font-normal tracking-[0.24px] text-blue_gray-900 lg:text-[20px]"
                      >
                        76.411
                      </Text>
                    </div>
                    <Img src="images/img_vector.png" alt="Vector Image" className="h-[50px] w-[10%] object-contain" />
                  </div>
                </div>
              </div>
              <div className="ml-[30px] grid w-full grid-cols-2 gap-[30px] gap-y-[30px] lg:grid-cols-2 md:ml-0 md:grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {productDetailsGrid.map((d, index) => (
                    <UserProfile {...d} key={"gridLayout" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
          <div className="flex justify-center rounded-[20px] bg-blue_gray-100 px-14 py-[88px] lg:py-8 md:p-5 sm:p-4">
            <Heading
              size="headingmd"
              as="h3"
              className="text-[20px] font-bold tracking-[0.20px] text-blue_gray-900 lg:text-[17px]"
            >
              Banner
            </Heading>
          </div>
        </div>
      </div>
    </>
  );
}
