import { Helmet } from "react-helmet";
import Header from "../../components/Header";
import FulanaProfileSection from "./FulanaProfileSection";
import HighlightsSection from "./HighlightsSection";
import MainContentSection from "./MainContentSection";
import React from "react";

export default function Frame426Page() {
  return (
    <>
      <Helmet>
        <title>Top Creators of the Week - Explore Now</title>
        <meta
          name="description"
          content="Discover the most popular content creators of the week. Browse through categories like Women, Couples, Trans, and Men. Join the community and create your profile today."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-[50px] bg-white-a700">
        <Header />
        <div className="mb-1">
          {/* highlights section */}
          <HighlightsSection />

          {/* main content section */}
          <MainContentSection />

          {/* fulana profile section */}
          <FulanaProfileSection />
        </div>
      </div>
    </>
  );
}
