import { Helmet } from "react-helmet";
import { Button, Text, Img } from "../../components";
import FramefourColumnTwo from "./FramefourColumnTwo";
import React from "react";

export default function FrameFourPage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Cadastro</title>
        <meta name="description" content="Cadastro" />
      </Helmet>
      <div className="w-full bg-white-a700 py-[58px] md:py-5 sm:py-4">
        <div className="mb-[104px] flex flex-col gap-[54px] sm:gap-[27px]">
          <div className="mx-20 flex items-center justify-between gap-5 md:mx-0 sm:items-center sm:justify-between sm:flex-row">
            {/* Ajuste da logo para ficar do mesmo tamanho do botão "Seja Premium" */}
            <div className="flex h-[50px] w-[8%] sm:w-[30%] sm:justify-end items-center justify-center"> {/* Ajustado para h-[50px] para igualar ao botão */}
              <Img
                src="/images/logo.png"
                alt="Logo"
                className="object-cover w-full h-full sm:w-[100px]" // Mantendo o tamanho igual ao do botão
              />
            </div>
            {/* Botão "Seja Premium" ajustado para o mesmo tamanho da logo */}
            <button className="premium-button min-w-[170px] sm:min-w-[120px] h-[50px] sm:h-[50px] self-center rounded-[10px] px-[30px] sm:px-[15px] font-bold tracking-[0.16px] flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap text-[16px] sm:text-[12px] bg-gradient shadow-xs text-white sm:self-start">
              Seja Premium
            </button>
          </div>
          <FramefourColumnTwo />
        </div>
      </div>
    </>
  );
}