import { Helmet } from "react-helmet";
import { Button, Text } from "../../components";
import FramethreeColumnTwo from "./FramethreeColumnTwo";
import React from "react";
import "./FrameThreePage.css"; // Importe o arquivo CSS

export default function FrameThreePage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Cadastro</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex w-full flex-col gap-9 bg-white-a700 py-[52px] md:py-5 sm:py-4">
        <div className="mx-20 flex items-start justify-center md:mx-0 md:flex-col">
          <div className="mt-1.5 flex h-[60px] w-[8%] items-center justify-center lg:h-auto md:h-auto md:w-full md:px-5 hide-mobile">
            <div className="flex w-full flex-wrap justify-center">
              <img src="/images/logo.png" alt="Logo" className="h-[60px] w-[150px] lg:w-[120px]" />
            </div>
          </div>
          <div className="flex flex-1 flex-wrap justify-center gap-5 px-14 md:self-stretch md:px-5">
            <img
              src="/images/123-2.png"
              alt="123-2"
              className="h-[58px] w-[200px] rounded-[20px] text-center lg:h-[50px] lg:w-[180px]"
            />
          </div>
          {/* Aplicando uma classe específica "seja-premium-hide-mobile" para esconder o botão no mobile */}
          <Button
            shape="round"
            className="animate-gradient mt-1.5 min-w-[170px] self-end font-bold tracking-[0.16px] md:self-auto seja-premium-hide-mobile"
          >
            Seja Premium
          </Button>
        </div>
        <FramethreeColumnTwo />
      </div>
    </>
  );
}
