import React from 'react';
import { Helmet } from 'react-helmet';
import FrameoneoneColumnTwo from './FrameoneoneColumnTwo';

export default function FrameOneOnePage() {
  return (
    <>
      <Helmet>
        <title>HotPlace-Cadastro</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-white-a700 py-[52px] md:py-5 sm:py-4">
        <div className="mb-[98px] flex flex-col gap-9">
          <div className="mx-20 flex items-center justify-center md:mx-0 md:flex-col gap-4">
            {/* Logo */}
            <img
              src="/images/logo.png"
              alt="Logo"
              className="object-contain"
              style={{ width: '100px', height: 'auto' }}
            />
            
            {/* Image 123-3 */}
            <img
              src="/images/123-3.png"
              alt="123-3"
              className="object-contain"
              style={{ width: '150px', height: 'auto' }}
            />
            
            {/* Button after 123-3 */}
            <button className="px-6 py-2 font-semibold text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-lg hover:from-pink-500 hover:to-yellow-500 transition-all duration-300 shadow-lg transform hover:scale-105">
              Seja Premium
            </button>
          </div>
          
          {/* Content below */}
          <FrameoneoneColumnTwo />
        </div>
      </div>
    </>
  );
}
