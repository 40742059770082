import { Button, Input, Text, Heading, Img } from "../../components";
import React from "react";

export default function FrameoneoneColumnTwo() {
  return (
    <div className="flex flex-col items-center">
      <div className="container-xs flex flex-col items-center gap-12 lg:px-5 md:px-5">
        <Heading
          size="text5xl"
          as="h2"
          className="text-center text-[22.68px] font-medium leading-[27px] tracking-[0.23px] text-black lg:text-[18px]"
        >
          <span>Após a conclusão,&nbsp;</span>
          <span className="font-light text-black">
            <>
              nosso suporte analisará e entrará em
              <br />
              contato sobre a aprovação, ativando seu perfil no catálogo.
            </>
          </span>
        </Heading>
        <div className="flex flex-col items-center justify-center gap-[50px] self-stretch rounded-[30px] bg-gray-50 p-[52px] shadow-xs md:p-5 sm:p-4">
          <div className="flex items-start gap-[15px]">
            <Img src="images/img_vector_36x36.png" alt="Vector" className="h-[36px] w-[36px] object-cover" />
            <Heading
              size="heading4xl"
              as="h3"
              className="mt-1.5 self-end text-[32px] font-bold tracking-[0.32px] text-black lg:text-[27px] md:text-[26px] sm:text-[24px]"
            >
              Dados Cadastrais
            </Heading>
          </div>
          <div className="flex w-[92%] justify-center lg:w-full md:w-full md:flex-col">
            <div className="flex w-full flex-col gap-[60px] sm:gap-[30px]">
              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Nome Completo
                </Text>
                <Input
                  shape="round"
                  name="nome_completo"
                  placeholder="Nome Completo"
                  className="mr-[30px] self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px] md:mr-0"
                />
              </div>
              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  WhatsApp para entrarmos em contato com você
                </Text>
                <Input
                  shape="round"
                  name="número_do"
                  placeholder="Número do WhatsApp"
                  className="mr-[30px] self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px] md:mr-0"
                />
              </div>
              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Nacionalidade
                </Text>
                <Input
                  shape="round"
                  name="nacionalidade"
                  placeholder="Nacionalidade"
                  className="mr-[30px] self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px] md:mr-0"
                />
              </div>
              <div className="flex flex-col items-start gap-5">
                <Text as="p" className="text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Número do documento
                </Text>
                <Input
                  shape="round"
                  name="número_do"
                  placeholder="Número do documento"
                  className="mr-[30px] self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px] md:mr-0"
                />
              </div>
            </div>
            <div className="flex w-full flex-col gap-[60px] px-1 sm:gap-[30px]">
              <div className="ml-6 flex flex-col items-start gap-5 md:ml-0">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Nome Artístico
                </Text>
                <Input
                  shape="round"
                  name="nome_artístico"
                  placeholder="Nome Artístico"
                  className="self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px]"
                />
              </div>
              <div className="ml-6 flex flex-col items-start gap-5 md:ml-0">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Data de Nascimento
                </Text>
                <Input
                  shape="round"
                  name="data_de"
                  placeholder="Data de Nascimento"
                  className="self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px]"
                />
              </div>
              <div className="ml-6 flex flex-col items-start gap-5 md:ml-0">
                <Text as="p" className="text-[18px] font-normal tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Tipo de Documento
                </Text>
                <Input
                  size="sm"
                  shape="round"
                  name="edittext"
                  className="self-stretch rounded-[10px] !border px-[18px]"
                />
              </div>
              <div className="ml-6 flex flex-col items-start gap-5 md:ml-0">
                <Text as="p" className="text-[18px] font-medium tracking-[0.18px] text-blue_gray-900_01 lg:text-[15px]">
                  Envie seu Documento
                </Text>
                <Input
                  shape="round"
                  name="enviar"
                  placeholder="Enviar Documento"
                  className="self-stretch rounded-[10px] !border px-[18px] italic tracking-[0.18px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-[30px]">
          <Button
            variant="fill"
            shape="round"
            className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4"
          >
            Voltar
          </Button>
          <Button
            shape="round"
            color="purple_A200_cyan_A100"
            className="min-w-[170px] rounded-[10px] px-[34px] font-bold tracking-[0.16px] sm:px-4"
          >
            Próximo
          </Button>
        </div>
      </div>
    </div>
  );
}
